// prefer default export if available
const preferDefault = m => m && m.default || m

exports.components = {
  "component---src-pages-404-js": () => import("./../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-how-oww-works-js": () => import("./../../src/pages/how-oww-works.js" /* webpackChunkName: "component---src-pages-how-oww-works-js" */),
  "component---src-pages-index-js": () => import("./../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-member-agreement-js": () => import("./../../src/pages/member-agreement.js" /* webpackChunkName: "component---src-pages-member-agreement-js" */),
  "component---src-pages-member-book-gallery-js": () => import("./../../src/pages/member-book-gallery.js" /* webpackChunkName: "component---src-pages-member-book-gallery-js" */),
  "component---src-pages-pricing-js": () => import("./../../src/pages/pricing.js" /* webpackChunkName: "component---src-pages-pricing-js" */),
  "component---src-pages-privacy-policy-js": () => import("./../../src/pages/privacy-policy.js" /* webpackChunkName: "component---src-pages-privacy-policy-js" */),
  "component---src-pages-what-we-offer-js": () => import("./../../src/pages/what-we-offer.js" /* webpackChunkName: "component---src-pages-what-we-offer-js" */),
  "component---src-pages-who-we-are-js": () => import("./../../src/pages/who-we-are.js" /* webpackChunkName: "component---src-pages-who-we-are-js" */)
}

